var $ = require("jquery");

function collapseNav() {
  $(".nav__item").removeClass("nav__item--active");
}

function openNav(elem) {
  if ($(elem).parent().hasClass("nav__item--active")) {
    $(elem).parent().removeClass("nav__item--active");
  }
  else {
    collapseNav();
    $(elem).parent().addClass("nav__item--active");
    document.body.addEventListener('click', outClick);
  }
}


function outClick(e) {
  var el = document.querySelector(".nav__item--active");
  if (!el.contains(e.target)) {
    collapseNav();
    setTimeout(function() {
      document.body.removeEventListener('click', outClick);
    }, 250);
  }
}

$(".nav__link").on("click", function() {
  openNav(this);
});
