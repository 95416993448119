module.exports = function(player) {
  var audio = jQuery(player).find('.audio')[0],
  play = jQuery(player).find('.btn__play')[0],
  icoPlay = jQuery(player).find('.btn__play__arrow')[0],
  icoPause = jQuery(player).find('.btn__play__pause')[0],
  volumeProgress = jQuery(player).find('.player__volume__progress')[0],
  volumeBar = jQuery(player).find('.player__volume__bar')[0],
  btnVolume = jQuery(player).find('.btn__volume')[0],
  timeline = jQuery(player).find('.player__timeline')[0],
  timeProgress = jQuery(player).find('.player__timeline__progress')[0],
  timeNow = jQuery(player).find('.player__timeline__time--now')[0],
  timeEnd = jQuery(player).find('.player__timeline__time--end')[0],
  duration;

  timeProgress.style.width = '0%';
  timeNow.innerHTML = '00:00:00';
  timeEnd.innerHTML = '00:00:00';
  audio.volume = 0.7;

  audio.addEventListener("loadedmetadata", function () {
    duration = audio.duration;
  }, false);

  function playAudio() {
    if (audio.paused) {
      audio.play();
      icoPause.style.display = 'block';
      icoPause.setAttribute('tab-index', '0');
      icoPlay.style.display = 'none';

    } else {
      audio.pause();
      icoPause.style.display = 'none';
      icoPlay.style.display = 'block';
    }
  }
  function uptadeTime() {
    if (duration) {
      var now = 100 * (audio.currentTime / duration);
      timeProgress.style.width = now + '%';
      timeNow.innerHTML = formatTime(audio.currentTime);

      timeEnd.innerHTML = formatTime(duration - audio.currentTime);
    }
  }

  function formatTime(secs){
    var times = new Array(3600, 60, 1);
    var time = '';
    var tmp;
    for(var i = 0; i < times.length; i++){
      tmp = Math.floor(secs / times[i]);
      if(tmp < 1){
        tmp = '00';
      }
      else if(tmp < 10){
        tmp = '0' + tmp;
      }
      time += tmp;
      if(i < 2){
        time += ':';
      }
      secs = secs % times[i];
    }
    return time;
  }

  function clickPercent(e) {
    const timelineLeft = jQuery(timeline).offset().left;
    const playerLeft   = jQuery(player).offset().left;

    const percent = ((e.pageX - timelineLeft) / jQuery(timeline).outerWidth());

    return percent;
  }

  function updateTimeline(e) {
    var newSize = e.pageX - jQuery(timeline).offset().left;

    if (newSize >= 0 && newSize <= jQuery(timeline).outerWidth()) {

      timeProgress.style.width = newSize + "px";
    }
    if (newSize < 0) {

      timeProgress.style.width = "0px";
    }
    if (newSize > timeline.offsetWidth) {

      timeProgress.style.width = jQuery(timeline).outerWidth() + "px";
    }
  }
  function updateAudio(e) {
    var percent = (
      (e.pageX - jQuery(volumeBar).offset().left) / jQuery(volumeBar).outerWidth());

    if (percent >= 0 && percent <= jQuery(volumeBar).outerWidth()) {
      volumeProgress.style.width = 100*percent + "%";
      btnVolume.classList.add('btn__volume--up');
    }
    if (percent <= 0) {
      volumeProgress.style.width = "0";
      btnVolume.classList.remove('btn__volume--up');
    }
    if (percent > volumeBar.offsetWidth) {
      volumeProgress.style.width = "100%";
      btnVolume.classList.add('btn__volume--up');
    }

    audio.volume = percent;
  }

  function autoStop() {
    if(audio.paused || audio.currentTime >= duration) {
      icoPause.style.display = 'none';
      icoPlay.style.display = 'block';
      audio.pause();
    }
  }

  function audioVolume() {
    if(audio.volume > 0) {
      audio.volume = 0;
      btnVolume.classList.remove('btn__volume--up');
      volumeProgress.style.width = "0%";
    }
    else {
      audio.volume = 0.7;
      btnVolume.classList.add('btn__volume--up');
      volumeProgress.style.width = "70%";
    }
  }

  //Events
  timeline.addEventListener("click", function (event) {
    updateTimeline(event);
    audio.currentTime = duration * clickPercent(event);
  }, false);
  audio.addEventListener("timeupdate", uptadeTime, false);
  audio.addEventListener("timeupdate", autoStop, false);
  play.addEventListener('click', function(e) {
    playAudio();
  });
  btnVolume.addEventListener('click',audioVolume,false);
  volumeBar.addEventListener('click',function(event) {
    updateAudio(event);
  });
}
