jQuery(function($) {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 800) {
            $('.back-to-top').fadeIn(100);
        } else {
            $('.back-to-top').fadeOut(100);
        }
    });
    $('.back-to-top').click(function () {
        $("html, body").animate({
            scrollTop: 0
        });
    });

});
