var $ = require("jquery");

// collapsed header
const canvas = document.querySelector(".page");
const header = document.querySelector(".header");

const watch = function watch() {
  const headerOffset = parseInt(
    getComputedStyle(header).getPropertyValue("padding-top"),
    10
  );
  const width = window.innerWidth;
  const scroll = document.body.scrollTop || document.documentElement.scrollTop;
  const limit = canvas.getBoundingClientRect().top + scroll + headerOffset;
  if (scroll > limit && width) {
    header.classList.add("header--collapsed");
  } else {
    header.classList.remove("header--collapsed");
  }
  requestAnimationFrame(watch);
};

if (header) {
  watch();
}

const body = $("body");

// menu
const activateMenu = function() {
  body.addClass("menu-active");
  $(".menu-toggle").addClass("tcon-transform");
  $(".menu-toggle").attr("aria-expanded", "true");
  deactivateSearch();
};
const deactivateMenu = function() {
  body.removeClass("menu-active");
  $(".menu-toggle").removeClass("tcon-transform");
  $(".menu-toggle").attr("aria-expanded", "false");
};
const toggleMenu = function() {
  if (body.hasClass("menu-active")) {
    deactivateMenu();
  } else {
    activateMenu();
  }
};

// search
const activateSearch = function() {
  body.addClass("search-active");
  $(".search-toggle").addClass("tcon-transform");
  $(".search-toggle").attr("aria-expanded", "true");
  deactivateMenu();
};
const deactivateSearch = function() {
  body.removeClass("search-active");
  $(".search-toggle").removeClass("tcon-transform");
  $(".search-toggle").attr("aria-expanded", "false");
};
const toggleSearch = function() {
  if (body.hasClass("search-active")) {
    deactivateSearch();
  } else {
    activateSearch();
  }
};

// close if hitting esc
const keydown = function(e) {
  if (e.keyCode === 27) {
    deactivateSearch();
    deactivateMenu();
  }
  document.removeEventListener("keydown", keydown);
};

// toggle menu/seearch

$(".menu-toggle").on("click", function() {
  toggleMenu();
  document.addEventListener("keydown", keydown);
});

$(".search-toggle").on("click", function() {
  toggleSearch();
  document.addEventListener("keydown", keydown);
});
