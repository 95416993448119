  jQuery(function($) {

    var toggleSubnav = function(elem) {
      $(elem).parent().toggleClass('subnav--active')
    }


      $('.subnav__toggle').on('click', function() {
        toggleSubnav(this);
      });




  });
