jQuery(function($) {
  $('.faculty__slider').slick({
    appendArrows: $('.faculty--arrow').find('.slider__nav'),
    nextArrow: '<button aria-label="next" class="slick-next slick-arrow"><span class="svgstore svgstore--ArrowRight"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowRight"></use></svg></span></button>',
    prevArrow: '<button aria-label="prev" class="slick-prev slick-arrow"><span class="svgstore svgstore--ArrowLeft"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowLeft"></use></svg></span></button>',
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [ {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  });

  $('.faculty__slider--alt').slick({
    appendArrows: $('.faculty--arrow').find('.slider__nav--alt'),
    nextArrow: '<button aria-label="next" class="slick-next slick-arrow"><span class="svgstore svgstore--ArrowRight"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowRight"></use></svg></span></button>',
    prevArrow: '<button aria-label="prev" class="slick-prev slick-arrow"><span class="svgstore svgstore--ArrowLeft"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowLeft"></use></svg></span></button>',
  })

var screenSize = $(window).width();
  if (screenSize > 767) {
    $('.faculty__match-height').matchHeight();
    $('.image__match-height').matchHeight();
  }
});
