  jQuery(function($) {

    if ($('.figure__donut').length > 0) {

      var calcDonut = function(donut) {
        var getDonutPercent = donut.attr('data-calc-donut');
        var setDonutPercent = Math.floor(503 * (1 - getDonutPercent));

        setTimeout(function() {
          donut.addClass('filled');
          donut.find('.circle-front').css({
            "stroke-dashoffset": setDonutPercent + "px"
          });
        }, 250);
      }


      var debounce = require('../util/_debounce');
      var win = $(window);
      var winHeight;
      var winPosition;

      var blockSetup = function() {
        winHeight = win.height();
      }

      var blockScroll = function() {
        winPosition = win.scrollTop() + winHeight;
        $('.figure__donut').each(function() {
          var block = $(this);
          if (!block.hasClass('filled')) {
            var limit = block.offset().top + 101;
            if (winPosition > limit && block.parent().hasClass('slick-active')) {
              var findDonut = block.closest('.figure__donut');
              calcDonut(findDonut)
            }
          }
        });
      }

      var blockUpdate = function() {
        requestAnimationFrame(blockUpdate);
        blockScroll();
      }

      win.on('load resize', debounce(function() {
        blockSetup();
      }, 250));

      win.on('load', function() {
        blockUpdate();
      });

  }




// Slider

$('.figure__slider').slick({
  appendArrows: $('.figure--arrow').find('.slider__nav--figure'),
  nextArrow: '<button aria-label="next" class="slick-next slick-arrow"><span class="svgstore svgstore--ArrowRight"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowRight"></use></svg></span></button>',
  prevArrow: '<button aria-label="prev" class="slick-prev slick-arrow"><span class="svgstore svgstore--ArrowLeft"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowLeft"></use></svg></span></button>',
  slidesToShow: 3,
  // infinite: false,

  responsive: [ {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
});

$('.figure__slider--alt').slick({
  appendArrows: $('.figure--arrow').find('.slider__nav--figure'),
  nextArrow: '<button aria-label="next" class="slick-next slick-arrow"><span class="svgstore svgstore--ArrowRight"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowRight"></use></svg></span></button>',
  prevArrow: '<button aria-label="prev" class="slick-prev slick-arrow"><span class="svgstore svgstore--ArrowLeft"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="img/svgstore.svg#ArrowLeft"></use></svg></span></button>',
  slidesToShow: 2,
  // infinite: false,
  responsive: [ {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
})



});


