// Global animation interactions

var debounce = require('../util/_debounce');

jQuery(function($) {
  var win = $(window);
  var winHeight;
  var winPosition;

  var blockSetup = function() {
    winHeight = win.height();
  }

  var blockScroll = function() {
    winPosition = win.scrollTop() + winHeight;
    $('.animate').each(function() {
      var block = $(this);
      if (!block.hasClass('animated')) {
        var limit = block.offset().top + 101;
        if (winPosition > limit) {
          $(this).addClass('animated').addClass('fadeIn');
        }
      }
    });
  }

  var blockUpdate = function() {
    requestAnimationFrame(blockUpdate);
    blockScroll();
  }

  win.on('load resize', debounce(function() {
    blockSetup();
  }, 250));

  win.on('load', function() {
    blockUpdate();
  });


// ignore mobile here
  var mobileBlockSetup = function() {
    winHeight = win.height();
  }

  var mobileBlockScroll = function() {
    winPosition = win.scrollTop() + winHeight;
    $('.animate-text').each(function() {
      var mobileBlock = $(this);
      if (!mobileBlock.hasClass('animated-text')) {
        var limit = mobileBlock.offset().top + 101;
        if (winPosition > limit) {
          $(this).addClass('animated-text').addClass('fadeIn');
        }
      }
    });
  }

  var mobileBlockUpdate = function() {
    requestAnimationFrame(mobileBlockUpdate);
    mobileBlockScroll();
  }

  win.on('load resize', debounce(function() {
    mobileBlockSetup();
  }, 250));

  win.on('load', function() {
    mobileBlockUpdate();
  });







});
