// customized modernizr build
require("./util/_modernizr");

// svg polyfill for better xlink support
var svg4everybody = require("svg4everybody");
svg4everybody();

// global jquery
global.jQuery = require("jquery");
require("jquery-ui");

// placeholder attribute polyfill
require("jquery-placeholder");
jQuery("input, textarea").placeholder();

// match height
require("./util/_match-height.js");

// srcset picture polyfill
require("respimage");

// object-fit polyfill
var objectFitImages = require("object-fit-images");
objectFitImages();

// lazyload images
require("lazysizes");

// modal
require("./util/_modal.js");

// Jump nav (onpage) offset
require("./util/_jump-nav-offset.js");

// Slick slider
require("slick-carousel");

// automatically require all modules
require('./modules/_alert.js');require('./modules/_animate.js');require('./modules/_audioplayer.js');require('./modules/_backToTop.js');require('./modules/_dropdown.js');require('./modules/_faculty.js');require('./modules/_figure.js');require('./modules/_form.js');require('./modules/_header.js');require('./modules/_hero.js');require('./modules/_home.js');require('./modules/_nav.js');require('./modules/_subnav.js');require('./modules/_tabs.js');

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

if (iOS) {
  jQuery("html").addClass("iOS");
}

// icon transform
require("./util/_icons");

// Export accordions
const initAccordions = require("./util/_accordion.js");
initAccordions();

// focus within polyfill
var focusWithin = require("ally.js/style/focus-within");
focusWithin();
