jQuery(function($) {

// Checkbox & radio button accessibility
  var toggleCheckbox = function(elem) {
    if ($(elem).attr('aria-checked') == 'false') {
      $(elem).attr('aria-checked', 'true');
    } else {
      $(elem).attr('aria-checked', 'false');
    }
  }

  $('.form__checkbox').on('click keypress', function() {
    toggleCheckbox(this);
  });

  var toggleRadio = function(elem) {
    if ($(elem).attr('aria-checked') == 'false') {
      $(elem).attr('aria-checked', 'true');
    } else {
      $(elem).attr('aria-checked', 'false');
    }
  }

  $('.form__radio').on('click keypress', function() {
    toggleRadio(this);
  });
});
