module.exports = function() {

jQuery(function($) {
  $('.accordion__toggle').on('click', function(e) {
    $(this).closest('.accordion__item').toggleClass('accordion__item--active');
    $(this).closest('.accordion__item').attr('aria-expanded', function(i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
        $(this).closest('.accordion__item').children().children('.accordion__interior').attr('aria-expanded', function(i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
    e.preventDefault();
  });
});

}

